import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Magic } from 'magic-sdk';

// Selectors //
import { createStructuredSelector } from 'reselect';
import { makeSelectUnreadCount } from 'data/chat/selectors';



// Components //
import {
    ChatIcon,
    CloseIcon,
    HangUpIcon,
    MicOffIcon,
    MicOnIcon,
    VideoOffIcon,
    VideoOnIcon,
    PeopleIcon,
    SettingsIcon,
    ShareScreenIcon,
    ShareScreenOffIcon,
    ShareVideoOnIcon,
    ShareVideoOffIcon
} from 'components/Icons';
import ActionButton from './ActionButton';

const magic = new Magic(process.env.REACT_APP_PK_KEY);

export const logoutUser = async () => {
    localStorage.removeItem("user")
    localStorage.removeItem("val")
    localStorage.setItem("conferenceAlias", "null")
    await magic.user.logout();
    window.location.pathname = "thanks";
};

const Root = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    padding: 16px 48px;
    margin-right: ${({ sidebarOpen }) => (sidebarOpen ? 376 : 0)}px;
    transition: margin-right 250ms;
`;

const MainControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 16px;
    }
`;

const Amphi = styled.div`
    display: flex;
    align-items: left;
    & > * + * {
        margin-left: 16px;
    }
    position: absolute;
    bottom: 100px;
    left: 20vw;
`;

const Audience = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    margin-bottom: 32px;
    border: 1px solid white;
`;

const ActionButtons = ({
    attendeesChatOpened,
    attendeesListOpened,
    attendeesSettingsOpened,
    isMuted,
    isScreenshare,
    isVideoPresentation,
    leave,
    toggleAttendeesChat,
    toggleAttendeesList,
    toggleAttendeesSettings,
    toggleMicrophone,
    toggleScreenShare,
    toggleVideoPresentation,
    toggleVideo,
    videoEnabled,
    unreadCount,
    ...props
}) => {
    const sidebarOpen = attendeesChatOpened || attendeesListOpened;

    let role = localStorage.getItem("role");

    let participants = props.participants;
    console.log(props);

    if(role=="admin" || role=="user"){
        return (
                <Root sidebarOpen={sidebarOpen}>
                    <Actions>
                        <ActionButton
                           icon={CloseIcon}
                           onClick={() => {
                               // Are you sure?

                               if(window.confirm("Are you sure you want to log out?"))
                                   logoutUser();
                           }
                           }
                           size={40}
                        />
                        <ActionButton
                            icon={attendeesSettingsOpened ? CloseIcon : SettingsIcon}
                            onClick={toggleAttendeesSettings}
                            size={40}
                        />
                        <ActionButton
                           icon={isScreenshare ? ShareScreenOffIcon : ShareScreenIcon}
                           onClick={() => toggleScreenShare('screenshare')}
                           size={40}
                        />
                        <ActionButton
                           icon={ isVideoPresentation ? ShareVideoOffIcon : ShareVideoOnIcon }
                           onClick={() => {
                               if(isVideoPresentation){
                                   toggleVideoPresentation();
                                }else{
                                    toggleVideoPresentation(window.prompt("Please paste video URL here","https://www.youtube.com/watch?v=MXtbeSbf_xM"))
                                }
                           }}
                           size={40}
                        />
                    </Actions>
                    <MainControls>
                        <ActionButton icon={isMuted ? MicOffIcon : MicOnIcon} onClick={toggleMicrophone} size={40} />
                        <ActionButton color='red' icon={HangUpIcon} onClick={leave} />
                        <ActionButton
                            enabled={videoEnabled}
                            icon={videoEnabled ? VideoOnIcon : VideoOffIcon}
                            onClick={toggleVideo}
                            size={40}
                        />
                    </MainControls>
                    <Actions>
                        <ActionButton
                            icon={attendeesListOpened ? CloseIcon : PeopleIcon}
                            onClick={toggleAttendeesList}
                            size={40}
                        />
                        <ActionButton
                            showBadge={unreadCount > 0}
                            icon={attendeesChatOpened ? CloseIcon : ChatIcon}
                            onClick={toggleAttendeesChat}
                            size={40}
                        />
                    </Actions>
                    <Amphi>
                        {
                            participants.map((user) => <Audience src="${user.avatarUrl}" />)
                        }
                    </Amphi>
                </Root>
            );
    }else{
            return (
                <Root sidebarOpen={sidebarOpen}>
                    <Actions>
                        <ActionButton
                           icon={CloseIcon}
                           onClick={() => logoutUser()}
                           size={40}
                        />
                        <ActionButton
                            icon={attendeesSettingsOpened ? CloseIcon : SettingsIcon}
                            onClick={toggleAttendeesSettings}
                            size={40}
                        />
                    </Actions>
                    <MainControls>
                        <ActionButton color='red' icon={HangUpIcon} onClick={leave} />
                    </MainControls>
                    <Actions>
                        <ActionButton
                            icon={attendeesListOpened ? CloseIcon : PeopleIcon}
                            onClick={toggleAttendeesList}
                            size={40}
                        />
                        <ActionButton
                            showBadge={unreadCount > 0}
                            icon={attendeesChatOpened ? CloseIcon : ChatIcon}
                            onClick={toggleAttendeesChat}
                            size={40}
                        />
                    </Actions>
                    <Amphi>
                        {
                            participants.map((user) => <Audience src="${user.avatarUrl}" />)
                        }
                    </Amphi>
                </Root>
            );
    }
};

const mapStateToProps = createStructuredSelector({
    unreadCount: makeSelectUnreadCount(),
});

export default connect(mapStateToProps)(ActionButtons);
