import React, { Component } from 'react';
import styled from 'styled-components';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, Window, Thread, MessageList, MessageInput, Message, MessageSimple} from 'stream-chat-react';
import { withRouter } from 'react-router-dom';
import Animated from 'animated/lib/targets/react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';



// Redux //
import { toggleAttendeesChat, setUnreadCount } from 'data/chat/actions';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser, makeSelectStreamToken } from 'data/auth/selectors';

import { loginRequest } from '../../../../data/auth/actions';

// Components //
import Portal from 'utils/Portal';
import ChatHeader from './ChatHeader';

const Root = styled(Animated.div)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    max-width: 376px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.trueblack};
`;

class AttendeesChat extends Component {
    anim = new Animated.Value(0);

    constructor(props) {
        super(props);
        this.chatClient = new StreamChat(process.env.REACT_APP_STREAM_KEY);
        this.state = {
            channel: null,
            unmount: true,
        };
    }

    async componentDidMount() {
        const { match, user, streamToken } = this.props;

        await this.chatClient.setUser(user, streamToken);
        const channel = await this.chatClient.channel('messaging', match.params.conferenceAlias, {
            name: 'Video Call',
        });

        await this.setState({
            channel,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const { attendeesChatOpened, setUnreadCount } = this.props;
        const { channel } = this.state;



        if (!prevState.channel && channel) {
            this.init();
        }

        if (!prevProps.attendeesChatOpened && attendeesChatOpened) {
            await this.setState({ unmount: false });
            setUnreadCount(0);
            document.body.classList.add('chat-open');
            Animated.timing(this.anim, {
                toValue: 1,
                duration: 250,
            }).start();
        } else if (prevProps.attendeesChatOpened && !attendeesChatOpened) {
            document.body.classList.remove('chat-open');
            Animated.timing(this.anim, {
                toValue: 0,
                duration: 250,
            }).start(() => {
                this.setState({
                    unmount: true,
                });
            });
        }
    }

    async init() {
        const { channel } = this.state;
        const { user, match} = this.props;

        await channel.watch();

        channel.on('message.new', this.handleNewMessage);

        //console.log("HELLOOOOO", match)
        channel.on('message.new', event => {
            // three things can happen...
//    - update user to:
        //- Moderator
        //- On Stage
        //- Listener

            // cmd participantid moderator
            // cmd participantid onstage
            // cmd participantid listener
            //console.log("cmd?", String(event.message.text).split(" ")[0] == "cmd");
           console.log("event::::::::" , event.message);
           //console.log(localStorage);

            //console.log("role?", event.message.user.role == "admin")

            if(event.message.user.role == "admin" && event.message.mentioned_users.length !=0 && event.message.mentioned_users[0].id == user.id){
                console.log("user mentioned");

                let val = JSON.parse(localStorage.getItem("val"));
                let conferenceAlias = localStorage.getItem("conferenceAlias");

                let cmd = event.message.text.split(" ").slice(-1);
                if(cmd == "mod"){
                    localStorage.setItem("role", "admin");

                    loginRequest(val, conferenceAlias, "admin");
                    window.location.reload(false);
                }else if(cmd=="stage"){
                    localStorage.setItem("role", "user");

                    loginRequest(val, conferenceAlias, "channel_member");
                    window.location.reload(false);
                }else if(cmd=="sit"){
                    localStorage.setItem("role", "channel_member");

                    loginRequest(val, conferenceAlias, "channel_member");
                    window.location.reload(false);
                }
            }
            
            if(String(event.message.text).split(" ")[0] == "cmd" && event.message.user.role == "admin" && String(event.message.text).split(" ")[1] == user.id){
                // process the command...

                let cmd = String(event.message.text).split(" ")[2];

                let val = JSON.parse(localStorage.getItem("val"));
                let conferenceAlias = localStorage.getItem("conferenceAlias");

                //alert("the user's role is:" + event.message.user.role);
                if(cmd == "mod"){
                    localStorage.setItem("role", "admin");

                    loginRequest(val, conferenceAlias, "admin");
                    window.location.reload(false);
                }else if(cmd=="stage"){
                    localStorage.setItem("role", "user");

                    loginRequest(val, conferenceAlias, "channel_member");
                    window.location.reload(false);
                }else if(cmd=="sit"){
                    localStorage.setItem("role", "channel_member");

                    loginRequest(val, conferenceAlias, "channel_member");
                    window.location.reload(false);
                }
            }

            if(String(event.message.text).split(" ")[0] == "cmd" && event.message.user.role == "admin" && event.message.user.id == user.id){
                // we check if its yours truly. if so lets update the message with something else.
                console.log("you're an admin", event.message);
                /*
                const message = { 
                  id: event.message.id, 
                  text: 'the edited version of my text' 
                };

                const update = await this.chatClient.updateMessage(message);
                */
            }
            //console.log('received a new message', event.message);
        });
    }

    handleNewMessage = async () => {
        const { attendeesChatOpened, setUnreadCount } = this.props;
        const { channel } = this.state;
        const unread = await channel.countUnread();
        setUnreadCount(attendeesChatOpened ? 0 : unread);
    };

    mentionsClick = async (event, user) => {
        if(user){
            //console.log("event::::" , event);
            //console.log("user:::::", user);
            //console.log("user id: \n" + user[0].id);
            navigator.clipboard.writeText("cmd " + user[0].id)
        }
    }

    get rootStyle() {
        return {
            transform: [
                {
                    translateX: this.anim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['100%', '0%'],
                    }),
                },
            ],
        };
    }

    render() {
        const { toggleAttendeesChat } = this.props;
        const { channel, unmount } = this.state;
        if (!channel || unmount) {
            return null;
        }

        return (
            <Portal>
                <Root style={this.rootStyle}>
                    <Chat client={this.chatClient} theme='messaging dark'>
                        <Channel channel={channel}>
                            <Window hideOnThread>
                                <ChatHeader onClose={toggleAttendeesChat} />
                                <MessageList
                                    MessageSimple={this.renderMessage}
                                    onMentionsClick={this.mentionsClick}
                                 />
                                <MessageInput />
                            </Window>
                            <Thread Message={Message} fullWidth />
                        </Channel>
                    </Chat>
                </Root>
            </Portal>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: makeSelectCurrentUser(),
    streamToken: makeSelectStreamToken(),
});

export default compose(
    connect(
        mapStateToProps,
        { toggleAttendeesChat, setUnreadCount },
    ),
    withRouter,
)(AttendeesChat);
