import { all, call, put } from 'redux-saga/effects';
import shortid from 'shortid';

// Utils //
import history from 'utils/history';
import fetch from 'utils/fetch';

// Types //
import { LOGIN } from '../types';

import { Magic } from 'magic-sdk';
const magic = new Magic(process.env.REACT_APP_PK_KEY);

export const checkUser = async (cb) => {
  const isLoggedIn = await magic.user.isLoggedIn();
  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    return cb({ isLoggedIn: true, email: user.email });
  }
  return cb({ isLoggedIn: false });
};

export const loginUser = async (email) => {
  await magic.auth.loginWithMagicLink({ email });
};

export const logoutUser = async () => {
  await magic.user.logout();
};


async function logg (email) {
  await checkUser(async function(out){
      if(!out.isLoggedIn)
          await loginUser(email);
  })
}

export default function*({ conferenceAlias, data: { name, email }, role }) {
    try {

        const response = yield call(logg, email);

        const {
            data: { token, user },
        } = yield call(fetch, 'post', '/token', {
            role,
            name, 
            email
        });

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('streamToken', token);

        if (!conferenceAlias) {
            conferenceAlias = shortid();
        }

        yield all([
            put({
                type: LOGIN.SUCCESS,
                user,
                token,
            }),
            call(history.push, `/${conferenceAlias}`),
        ]);
    } catch (error) {
        yield put({
            type: LOGIN.ERROR,
            error,
        });
    }
}
