import React from 'react';
import Icon from '../Icon';

export default (props) => (
    <Icon {...props} viewBox='0 0 23 22'>
		<g fill="#000000" fill-rule="nonzero">
            <g>
                <path d="M3.06157314,0.93098014 C2.3022068,0.93098014 1.68220846,1.55097848 1.68220846,2.31034483 L1.68220846,6.72413793 C1.68085582,6.8236237 1.73312495,6.91616038 1.81905531,6.96631385 C1.90498567,7.01646733 2.01126407,7.01646733 2.09719443,6.96631385 C2.18312479,6.91616038 2.23539391,6.8236237 2.23398694,6.72413793 L2.23398694,2.31034483 C2.23398694,1.84709048 2.5983188,1.48275862 3.06157314,1.48275862 L18.0443318,1.48275862 C18.1438175,1.4841656 18.2363542,1.43189647 18.2865077,1.34596611 C18.3366612,1.26003575 18.3366612,1.15375735 18.2865077,1.06782699 C18.2363542,0.981896633 18.1438175,0.929627506 18.0443318,0.93098014 L3.06157314,0.93098014 Z" id="Path"></path>
                <path d="M20.9064007,0.931034483 C20.7540463,0.945317715 20.6421175,1.08040421 20.6564007,1.23275862 C20.670684,1.38511303 20.8057705,1.49704184 20.9581249,1.48275862 L22.371918,1.48275862 C22.8351723,1.48275862 23.1995042,1.84709048 23.1995042,2.31034483 L23.1995042,14.4482759 C23.1995042,14.9115302 22.8351723,15.2758621 22.371918,15.2758621 L10.7857111,15.2758621 C10.6862253,15.2744551 10.5936886,15.3267242 10.5435352,15.4126546 C10.4933817,15.4985849 10.4933817,15.6048633 10.5435352,15.6907937 C10.5936886,15.7767241 10.6862253,15.8289932 10.7857111,15.8276139 L22.371918,15.8276139 C23.1312843,15.8276139 23.7512283,15.2076422 23.7512283,14.4482759 L23.7512283,2.31034483 C23.7512283,1.55097848 23.1312843,0.931034483 22.371918,0.931034483 L20.9581249,0.931034483 C20.9495089,0.930731275 20.9408787,0.930731275 20.9322628,0.931034483 C20.9236468,0.930731275 20.9150167,0.930731275 20.9064007,0.931034483 Z" id="Path"></path>
                <path d="M0.251228317,8.65517241 C0.0988739066,8.66945564 -0.0130549011,8.80454214 0.00122832493,8.95689655 C0.0155115509,9.10925096 0.150598044,9.22117977 0.302952455,9.20689655 C4.72449811,9.20689655 8.30295245,12.7853506 8.30295245,17.2068966 C8.30154548,17.3063823 8.35381461,17.398919 8.43974496,17.4490725 C8.52567532,17.499226 8.63195372,17.499226 8.71788408,17.4490725 C8.80381444,17.398919 8.85608357,17.3063823 8.8547043,17.2068966 C8.8547043,12.4871768 5.0226719,8.65517241 0.302952455,8.65517241 C0.294336505,8.65476814 0.285706336,8.65476814 0.277090386,8.65517241 C0.268474426,8.65476854 0.259844276,8.65476854 0.251228317,8.65517241 Z" id="Path"></path>
                <path d="M0.251228317,10.862069 C0.152672292,10.8713086 0.0665320519,10.9324254 0.0252557915,11.0223972 C-0.0160204689,11.112369 -0.00616190389,11.2175271 0.0511178605,11.2982593 C0.108397625,11.3789915 0.20439643,11.4230327 0.302952455,11.4137931 C3.5056628,11.4137931 6.0960559,14.0041862 6.0960559,17.2068966 C6.09464893,17.3063823 6.14691805,17.398919 6.23284841,17.4490725 C6.31877877,17.499226 6.42505717,17.499226 6.51098753,17.4490725 C6.59691789,17.398919 6.64918702,17.3063823 6.64780775,17.2068966 C6.64780775,13.7060124 3.80383659,10.862069 0.302952455,10.862069 C0.294336505,10.8616647 0.285706336,10.8616647 0.277090386,10.862069 C0.268474426,10.8616651 0.259844276,10.8616651 0.251228317,10.862069 Z" id="Path"></path>
                <path d="M0.251228317,13.0689655 C0.098873903,13.0832487 -0.0130549096,13.2183352 0.00122831671,13.3706897 C0.015511543,13.5230441 0.150598041,13.6349729 0.302952455,13.6206897 C2.28682721,13.6206897 3.88915935,15.2230218 3.88915935,17.2068966 C3.88775237,17.3063823 3.9400215,17.398919 4.02595186,17.4490725 C4.11188222,17.499226 4.21816062,17.499226 4.30409098,17.4490725 C4.39002134,17.398919 4.44229047,17.3063823 4.44091119,17.2068966 C4.44091119,14.924848 2.58500128,13.0689655 0.302952455,13.0689655 C0.294336505,13.0685612 0.285706336,13.0685612 0.277090386,13.0689655 C0.268474426,13.0685616 0.259844276,13.0685616 0.251228317,13.0689655 Z" id="Path"></path>
                <polygon id="x" points="10.5374352 12.2413793 13.0201938 8.91724138 15.44778 12.2413793 16.1650214 12.2413793 13.3650214 8.51724138 15.9305387 5.15172414 15.2546766 5.15172414 13.0201938 8.11724138 10.7995042 5.15172414 10.0684697 5.15172414 12.6615731 8.51724138 9.87536625 12.2413793"></polygon>
            </g>
        </g>
	</Icon>
);
