import { LOGIN } from './types';

export const loginRequest = (data, conferenceAlias, role) => {

	return ({
	    type: LOGIN.REQUEST,
	    conferenceAlias,
	    data,
	    role,
	})
};
