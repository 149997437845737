import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import shortid from 'shortid';

// Redux //
import { createStructuredSelector } from 'reselect';
import { makeSelectIsAuthed, makeSelectCurrentUser } from 'data/auth/selectors';
import { loginRequest } from 'data/auth/actions';
import { queueSnackbar } from 'containers/Snackbar/actions';

// Assets //
import BackgroundImg from 'assets/bg.jpg';
import AmphiLogo from 'assets/amphi.svg';

// Forms //
import LoginForm from 'forms/LoginForm';

// Components //
import Button from 'components/Button';
import Logo from 'components/Logo';
import Text from 'components/Text';

const Root = styled.div`
    display: flex;
    flex: 1;
    background-image: url(${BackgroundImg});
    background-size: cover;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colorUtils.fade(theme.color.background, 0.88)};
`;

const Container = styled.div`
    max-width: 1280px;
    width: 100%;
    padding: 0px 24px;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
`;


const Foot = styled.div`
    position: absolute;
    left: 10vw;
    bottom: 10vh;
}
`;

const Title = styled(Text)`
    margin-top: 16px;
    text-transform: capitalize;
`;

const Avatar = styled.div`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    margin-bottom: 16px;
`;

const Header = styled.div`
    display: flex;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 24px 40px 24px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const CallBtn = styled(Button)`
    margin-top: 32px;
`;

const Stream = styled.img`
    max-height: 96px;
    margin-left: 40px;
`;

class Login extends Component {
    get conferenceAlias() {
        const { location } = this.props;
        return location.state ? location.state.conferenceAlias : null;
    }

    async componentDidMount() {
        const { queueSnackbar } = this.props;
        if (this.conferenceAlias) {
            await queueSnackbar({
                isError: true,
                text: 'Please login first.',
            });
        }
    }

    get userName() {
        const { user } = this.props;
        if(!user || !user.name){
            return "Demo";
        }else{
            return user.name.replace(/_/g, ' ');
        }
   }
    startCall = () => {
        const { history } = this.props;
        const conferenceAlias = shortid();
        history.push(`/${conferenceAlias}`);
    };

    handleLogin = (val) => {
        const { loginRequest, location } = this.props;

        let role = localStorage.getItem("role");

        if(location.search.includes("?role=admin")){
            role="admin";
            localStorage.setItem("role", "admin");
        }

        if(role=="admin"){
            role="admin";
        } else {
            role="channel_member";
            localStorage.setItem("role", "channel_member");
        }

        localStorage.setItem("val", JSON.stringify(val));
        localStorage.setItem("conferenceAlias", this.conferenceAlias);

        loginRequest(val, this.conferenceAlias, role);
    };

    renderForm = () => {
        
        return (
            <Container>
                <Title size={40} weight='600'>
                    Welcome to your online venue.
                </Title>
                <Text size={24}>Please sign in to get started</Text>
                <LoginForm conferenceAlias={this.conferenceAlias} onSubmit={this.handleLogin} />
            </Container>
        );
    };

    renderWelcome = () => {
        const { user } = this.props;
        return (
            <Container>
                <Avatar src={user.image} />
                <Title size={32} weight='600'>
                    Welcome back, {this.userName}.
                </Title>
                <CallBtn label='Create a new Room' onClick={this.startCall} />
            </Container>
        );
    };

    render() {
        const { isAuthed } = this.props;
        return (
            <Root>
                <Overlay />
                <Header>
                    <Stream src={AmphiLogo} />
                </Header>
                {isAuthed ? this.renderWelcome() : this.renderForm()}
                <Foot>
                Powered By
                    <Logo color='white' size={48} />
                </Foot>
            </Root>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isAuthed: makeSelectIsAuthed(),
    user: makeSelectCurrentUser(),
});

export default connect(
    mapStateToProps,
    { loginRequest, queueSnackbar },
)(Login);
