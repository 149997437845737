import React from 'react';
import Icon from '../Icon';

export default (props) => (
    <Icon {...props} viewBox='0 0 23 22'>
		<g fill="#000000" fill-rule="nonzero">
            <g>
                <path d="M3.50553985,0.00109912694 C2.73075915,0.00109912694 2.09812414,0.613626362 2.09812414,1.36387442 L2.09812414,5.72466776 C2.09679538,5.82295893 2.15012552,5.91438444 2.23780017,5.96393569 C2.32547483,6.01348694 2.43391057,6.01348694 2.52158523,5.96393569 C2.60925989,5.91438444 2.66259003,5.82295893 2.66115449,5.72466776 L2.66115449,1.36387442 C2.66115449,0.90618272 3.03288192,0.546225667 3.50553985,0.546225667 L16.8127321,0.546225667 C16.9142374,0.547615749 17.0086524,0.495974257 17.059824,0.411075728 C17.1109955,0.3261772 17.1109955,0.221174967 17.059824,0.136276438 C17.0086524,0.0513779098 16.9142374,-0.000263582196 16.8127321,0.00109912694 L3.50553985,0.00109912694 Z" id="Path"></path>
                <path d="M20.3060624,0.0014044332 C20.1565296,0.0157865347 20.0466738,0.151808109 20.0606925,0.305217128 C20.0747112,0.458626146 20.2072958,0.571329737 20.3568286,0.556947648 L21.7444377,0.556947648 C22.1991124,0.556947648 22.5566967,0.923801443 22.5566967,1.39026247 L22.5566967,13.6122132 C22.5566967,14.0786742 22.1991124,14.445528 21.7444377,14.445528 L10.3728118,14.445528 C10.2751686,14.4441113 10.1843457,14.4967422 10.1351211,14.5832674 C10.0858965,14.6697926 10.0858965,14.7768067 10.1351211,14.8633318 C10.1843457,14.949857 10.2751686,15.0024879 10.3728118,15.0010991 L21.7444377,15.0010991 C22.4897403,15.0010991 23.0982026,14.3768359 23.0982026,13.6122132 L23.0982026,1.39026247 C23.0982026,0.625639733 22.4897403,0.0014044332 21.7444377,0.0014044332 L20.3568286,0.0014044332 C20.3483722,0.00109912694 20.3399019,0.00109912694 20.3314455,0.0014044332 C20.3229891,0.00109912694 20.3145188,0.00109912694 20.3060624,0.0014044332 Z" id="Path"></path>
                <path d="M10.3014968,4.00182747 C10.1818999,4.02639248 10.0960965,4.14007224 10.0982026,4.271117 L10.0982026,10.7340656 C10.0989829,10.8281504 10.1452827,10.914998 10.2203719,10.9631528 C10.2954611,11.0113076 10.388452,11.0137874 10.4656641,10.969694 L15.9691776,7.73821965 C16.0487933,7.6907809 16.0982026,7.60054839 16.0982026,7.50259132 C16.0982026,7.40463425 16.0487933,7.31440173 15.9691776,7.26696298 L10.4656641,4.03548866 C10.4228427,4.00965048 10.3739062,3.9979441 10.3249492,4.00182747 C10.317136,4.00143283 10.30931,4.00143283 10.3014968,4.00182747 Z" id="Path"></path>
                <path d="M0.35355333,8.00140811 C0.198698956,8.01596333 0.0849335225,8.15362224 0.0994511201,8.30887789 C0.113968718,8.46413354 0.251271827,8.57819377 0.406126202,8.56363856 C4.90022443,8.56363856 8.53739718,12.210236 8.53739718,16.7159801 C8.53596712,16.8173604 8.58909392,16.9116592 8.6764343,16.9627677 C8.76377468,17.0138763 8.87179699,17.0138763 8.95913737,16.9627677 C9.04647775,16.9116592 9.09960455,16.8173604 9.09820265,16.7159801 C9.09820265,11.9063842 5.20329092,8.00140811 0.406126202,8.00140811 C0.397368875,8.00099613 0.388597094,8.00099613 0.379839766,8.00140811 C0.371082429,8.00099654 0.362310667,8.00099654 0.35355333,8.00140811 Z" id="Path"></path>
                <path d="M0.324934519,10.0014195 C0.235982234,10.0111822 0.158235884,10.0757592 0.120981767,10.1708249 C0.0837276501,10.2658906 0.0926255525,10.3770023 0.144323721,10.4623053 C0.196021889,10.5476082 0.282666141,10.5941429 0.371618426,10.5843802 C3.26224235,10.5843802 5.60021597,13.3214316 5.60021597,16.7054674 C5.5989461,16.8105857 5.64612189,16.9083614 5.72367881,16.9613544 C5.80123573,17.0143474 5.89715788,17.0143474 5.97471481,16.9613544 C6.05227173,16.9083614 6.09944752,16.8105857 6.09820265,16.7054674 C6.09820265,13.0063763 3.53136076,10.0014195 0.371618426,10.0014195 C0.363842053,10.0009923 0.356052846,10.0009923 0.348276472,10.0014195 C0.340500091,10.0009928 0.332710901,10.0009928 0.324934519,10.0014195 Z" id="Path"></path>
                <path d="M0.38105937,12.0014423 C0.209524288,12.017607 0.0835041898,12.1704881 0.0995856038,12.3429117 C0.115667018,12.5153354 0.267760239,12.6420083 0.439295322,12.6258436 C2.67293012,12.6258436 4.47698797,14.4392467 4.47698797,16.684452 C4.47540386,16.7970427 4.53425341,16.901769 4.63100198,16.9585291 C4.72775055,17.0152891 4.84740887,17.0152891 4.94415744,16.9585291 C5.04090601,16.901769 5.09975556,16.7970427 5.09820265,16.684452 C5.09820265,14.1017952 3.00864284,12.0014423 0.439295322,12.0014423 C0.429594666,12.0009847 0.419878001,12.0009847 0.410177346,12.0014423 C0.40047668,12.0009852 0.390760036,12.0009852 0.38105937,12.0014423 Z" id="Path"></path>
            </g>
        </g>
	</Icon>
);
